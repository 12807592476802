import { Title } from '@solidjs/meta';
import { Button, Errors, Field, FieldErrors, Heading, Input, Label, Option, Select, TextField } from '@troon/ui';
import { Formspree, formspreeAction } from '@troon/forms';
import { createSignal, Match, Show, Switch } from 'solid-js';
import { IconCircleCheck } from '@troon/icons';
import { useSubmission } from '@solidjs/router';
import { getConfigValue } from '../../../../modules/config';
import { Content } from '../../../../components/content';
import { useUser } from '../../../../providers/user';

export default function SupplierDiversificationPage() {
	const [requireOther, setRequireOther] = createSignal(false);
	const user = useUser();
	const submission = useSubmission(action);

	return (
		<>
			<Title>Troon Supplier Diversification Initiative | Diversity, Equity, and Inclusion | Troon</Title>

			<Content size="small" class="flex flex-col gap-8">
				<Heading as="h1">Troon Supplier Diversification Initiative</Heading>

				<p>
					As the global leader in golf and club-related leisure and hospitality services, Troon recognizes the
					importance that diversity has not only in our company and how we grow the game(s), but also how we support
					business growth and delivering value to our clients through diversity within our industry supply chain. We
					recognize how diverse perspectives can generate creative ideas, products and solutions that will add value to
					our clients while creating new revenue opportunities for both our clients and our trusted supplier base.
				</p>

				<p>
					To that end our Supplier Diversity program provides a pathway for diverse suppliers to add value to our
					product and service offerings. Our goal is to promote economic opportunities for historically disempowered
					groups wherever we operate.
				</p>

				<p>
					Suppliers qualify by being at least 51 percent owned by people from an ethnic minority (as defined in each
					country or region), or by women, military veterans, those with disabilities or LGBT+ individuals.For
					consideration to be added as a diverse supplier of Troon, complete the following form. Following your
					submittal, your application will be reviewed by our Director of Diversity and SVP of Procurement. Official
					responses to supplier will be made within 30 days of submittal.
				</p>

				<p>
					Thank you in advance for your interest in Troon. We take pride in seeking suppliers that are just as diverse
					as our associates, clients, members and valued guests.
				</p>

				<Switch>
					<Match when={submission.result}>
						<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
							<IconCircleCheck class="text-8xl text-brand" />
							<Heading as="h1" size="h2">
								Request received!
							</Heading>
							<p class="text-lg">Thanks for contacting us! We will get in touch with you shortly.</p>
						</div>
					</Match>
					<Match when>
						<Formspree action={action}>
							<Heading as="h2" class="sr-only">
								Supplier Diversification consideration form
							</Heading>
							<TextField name="Brand name" required>
								<Label>What is your brand name?</Label>
								<Input autocomplete="organization" />
							</TextField>

							<TextField name="Company website" required>
								<Label>What is your company’s website</Label>
								<Input type="url" inputMode="url" autocomplete="url" />
							</TextField>

							<Field name="Product category" required>
								<Label>What category best describes your products and/or services</Label>
								<Select>
									<Option disabled selected class="hidden" />
									<Option>Golf Operations</Option>
									<Option>Retail</Option>
									<Option>Fitness</Option>
									<Option>Agronomy</Option>
									<Option>Food & Beverage</Option>
									<Option>FF&E</Option>
									<Option>Maintenance</Option>
								</Select>
							</Field>

							<Field name="Owner identification" required>
								<Label>Is your company owned by the following:</Label>
								<Select
									onChange={(event) => {
										setRequireOther(event.currentTarget.value === 'Other');
									}}
								>
									<Option disabled selected class="hidden" />
									<Option>Asian / Pacific Islander</Option>
									<Option>Black</Option>
									<Option>Service Disabled / Veteran</Option>
									<Option>Hispanic / Latino American</Option>
									<Option>LGBT+</Option>
									<Option>Native American / Native Indian</Option>
									<Option>Women</Option>
									<Option>Other</Option>
								</Select>
								<FieldErrors />
							</Field>

							<Show when={requireOther()}>
								<TextField name="Owner identification (other)" required={requireOther()}>
									<Label>If other, please specify</Label>
									<Input />
								</TextField>
							</Show>

							<TextField name="Annual sales volume" required>
								<Label>What is your company’s annual sales volume?</Label>
								<Input />
							</TextField>

							<TextField name="Business tenure" required>
								<Label>How long has your company been in business?</Label>
								<Input />
							</TextField>

							<fieldset class="flex flex-col gap-4">
								<legend class="text-xl font-semibold">Primary contact information</legend>

								<div class="flex gap-4">
									<TextField name="First name" required class="grow">
										<Label>First name</Label>
										<Input autocomplete="given-name" value={user()?.me.firstName} />
									</TextField>

									<TextField name="Last name" required class="grow">
										<Label>Last name</Label>
										<Input autocomplete="family-name" value={user()?.me.lastName} />
									</TextField>
								</div>

								<TextField name="Title">
									<Label>Title</Label>
									<Input />
								</TextField>

								<TextField name="email" required>
									<Label>Email address</Label>
									<Input autocomplete="email" inputMode="email" value={user()?.me.email} />
								</TextField>

								<TextField name="Phone number">
									<Label>Phone number</Label>
									<Input autocomplete="tel" inputMode="tel" />
								</TextField>
							</fieldset>

							<Errors />

							<Button type="submit" class="w-fit">
								Submit
							</Button>
						</Formspree>
					</Match>
				</Switch>
			</Content>
		</>
	);
}

const action = formspreeAction(getConfigValue('FORMSPREE_PROJECT_ID'), 'supplier-diversification');
